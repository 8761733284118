<template>
  <section>
    <special_message_banner/>
    <Multi_product_homepage v-if="category_of_product_variety === MULTI"/>
    <Couple_product_page v-else-if="category_of_product_variety === COUPLE" />
    <Single_product_page v-else />
  </section>
</template>

<script>
// Data imports
import { mapState } from "vuex"
import {
  COUPLE,
  MULTI,
  SINGLE
} from "../../../../data/product_variety_types"
import { CLIENT_STORE } from "../../constants/other"

const Multi_product_homepage = () => import("../default-pages/Multi_product_homepage")
const Couple_product_page = () => import("../page_content_types/Couple_product_page")
const Single_product_page = () => import("../page_content_types/Single_product_page")
const special_message_banner = () => import("../utils/special_message_banner")

export default {
  components: {
    Multi_product_homepage,
    Couple_product_page,
    Single_product_page,
    special_message_banner
  },
  data() {
    return {
      COUPLE,
      MULTI,
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "category_of_product_variety",
      "banners",
    ]),
    show_special_message_banner() {
      return this.banners &&
        this.banners.special_message_banner &&
        this.use_special_message_banner
    },
    use_special_message_banner() {
      return (
        this.category_of_product_variety === COUPLE ||
        this.category_of_product_variety === SINGLE
      ) ? this.banners?.special_message_banner?.show_on_single_and_couple : true
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .wrapper--orders {
    padding: 20px 0; // Overwriting default styles

    @media (max-width: $maximum-width) {
      padding: 20px;
    }
  }
</style>
